body {
  min-height: 100vh !important;
  background-image: url(background-bsa.svg);
  background-color: #315296;
  background-repeat: no-repeat;
  background-size: cover;
}

.logo-img {
  height: 32px;
  width: 120px;
}

.main-content {
  padding-top: 80px;
  min-height: 60vh;
}

.message-box {
  padding: 5px 24px;
  border-top: 1px solid #5e7fc3;
  border-bottom: 1px solid #5e7fc3;
  margin-top: 20px;
  background-color: rgba(45, 75, 137, 0.4);
}
body input[type="text"],
body input[type="password"],
body input[type="email"] {
  background-color: initial !important;
}

.custom-link {
  text-decoration: underline !important;
  cursor: pointer !important;
}

div.alert.alert-danger ul {
  margin-bottom: 0px !important;
}

div.activate-message.alert {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
  margin-bottom: 0.5rem !important;
}


